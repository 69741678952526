import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import axios, { AxiosInstance } from "axios";
import { ToastrService } from "ngx-toastr";
import { flowBasedRouting } from "src/app/shared/mock-data";
import { environment } from "src/environments/environment";
// import { QuoteSummaryService } from "src/app/shared/services/quote-summary/quote-summary.service";
import { SessionService } from "src/app/shared/services/session-service/session.service";
import { CUSTOMERUUVID, TOKEN } from "src/app/shared/session-variables";
import * as CryptoJS from 'crypto-js';
import { API_ENDPOINTS } from "src/app/shared/api-endpoints";

// import { TOKEN } from "src/assets/session-variables";
@Injectable({
  providedIn: "root",
})
export class BaseInterceptor {
  customerUuvid: any;
  flowdata = flowBasedRouting;
  tokenFromUI = "93389a26699r89k3";
  validateCustomerUuvid: any

  encrypted;

  constructor(private router: Router,
    private sessionService: SessionService,
    private sessionStorage: SessionService,
    // private generateTokenService: GenerateTokenService
  ) { }

  interceptor = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.request.use(
      async (request) => {
        let encryptedData;
        // const insurerURL = this.sessionService?.get("insurerName");
        // const reinsurerName = this.sessionService?.get("reinsurerName");
        this.customerUuvid = this.sessionService.get(CUSTOMERUUVID);
        let token = this.sessionService?.get('token');
        if (!token) {
          this.validateCustomerUuvid = await this.validateUUID(this.customerUuvid)
          if (this.customerUuvid && this.validateCustomerUuvid == 1) {
            token = await this.generateToken();
            // Generate new token using axios
          }
          else {
            this.router.navigateByUrl('/404');
          }
        }

        // const clientCode = this.sessionService?.get("clientCode");
        request.headers["Authorization"] = `Bearer ${token}`;
        // request.headers["Authorization"] = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiMTU2NTY3NyIsImlhdCI6MTcxNjUyOTE5NiwiZXhwIjoxNzE2NTcyMzk2fQ.Yet6ADa9EIx7PYLr15RVvV_XK7oUzUoMxe5ZqXN2wXw`;
        // request.headers["client_code"] = `${clientCode}`;
        // request.headers["insurerURL"] = `${insurerURL}`;
        // request.headers["reinsurerName"] = `${reinsurerName}`;
        // Encrypt the request data here
        if (request.data) {
          // Don't Encrypt the FormData if it exists
          if (request.data instanceof FormData) {
            request.data = request.data;
          }
          else {
            encryptedData = this.encryptUsingAES256(JSON.stringify(request.data));
            request.data = { encryptedData };
          }
        }
        return request;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        //  let insurerName = this.sessionService.get('insurerName')
        //  let reinsurerShortForm =  this.sessionService.get('reinsurerShortForm');
        if (error.response.status === 401) {
          this.router.navigateByUrl(`/login`)
          // this.toasterService.error(error?.response?.data?.message || "Unauthorized");
          // this.router.navigateByUrl(`/`);
        } else if (error.response?.status === 403) {
          // this.toasterService.error(error?.response?.data?.message || "Forbidden");
        } else if (error.response?.status === 404) {
          // await this.getCustomerLastWorkflow();

          // this.toasterService.error(error?.response?.data?.message || "Data not found");
        }
        else if (error.response?.status === 400) {
          // await this.getCustomerLastWorkflow();
          // this.toasterService.error(error?.response?.data?.message || "Something went wrong");
        }
        return Promise.reject(error);
      }
    );
  };

  encryptUsingAES256(req) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      req, _key, {
      keySize: 32,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    this.encrypted = encrypted.toString();
    return this.encrypted;
  }



  async generateToken() {
    const axiosInstance = axios.create({
      baseURL: environment.apiUrl,
    });
    try {
      let results = await axiosInstance.post(`${API_ENDPOINTS.salik}/${API_ENDPOINTS.generateToken}`, { customerUuvid: this.customerUuvid })
      if (results) {
        this.sessionStorage.set(TOKEN, results?.data.response.token);
        return results?.data.response.token
      }
    } catch (exception) {

    }

  }

  // async getCustomerLastWorkflow() {

  //   console.log('-------error--------');
  //   if (this.validateCustomerUuvid == 1) {
  //     const axiosInstance = axios.create({
  //       baseURL: environment.apiUrl,
  //     });
  //     try {
  //       let apiResponse = await axiosInstance.get(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.getLastWorkflow}`, {}); ``
  //       return apiResponse;
  //     } catch (exception) {
  //       throw exception
  //     }
  //   } else {
  //     this.router.navigateByUrl('/404');
  //   }

  // }


  // ---------------------------- validateCustomer UUvid -------------------------
  async validateUUID(uuid: string): Promise<any> {
    // console.log(uuid, '-----------uuvid');

    const axiosInstance = axios.create({
      baseURL: environment.apiUrl,
    });

    try {
      // Make API call to validate the UUID
      const apiResponse = await axiosInstance.get(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.validateUUvid}?customerUuvid=${uuid}`);
      // console.log(apiResponse, '-----------------api response ---------------------');
      if (apiResponse.status === 200) {
        return apiResponse['data']['response'][0]['customerUuvidCount'];
      }
      return 0

    } catch (error) {
      console.error('Error validating UUID:', error);
      return false; // Return false in case of error
    }
  }

}


