import { Component, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { QuoteSummaryService } from 'src/app/shared/services/quote-summary/quote-summary.service';
import { SessionService } from 'src/app/shared/services/session-service/session.service';
import { QUOTEENQUIRYID } from 'src/app/shared/session-variables';
@Component({
  selector: 'app-existing-liva-error',
  templateUrl: './existing-liva-error.component.html',
  styleUrls: ['./existing-liva-error.component.scss']
})
export class ExistingLivaErrorComponent {
  contactEmail: string = 'contactus@livainsurance.ae';
  quoteEnquiryId:any;
  quotationNumber:any;
  constructor( private sessionService: SessionService,
    private quoteSumaryService: QuoteSummaryService,
    private spinner: NgxSpinnerService) {}

  async ngOnInit() {
  this.quoteEnquiryId = this.sessionService.get(QUOTEENQUIRYID);
  this.quotationNumber = await this.getQuotationNumber();
  }

  async getQuotationNumber(){
    try {
      this.spinner.show();
      let quotationNumberResult = await this.quoteSumaryService.getQuotationNumber({quoteEnquiryId:this.quoteEnquiryId});
     return quotationNumberResult?.response;
    } catch (error) {

    }finally{
      this.spinner.hide()
    }
  }

}
