import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './modules/error/error/error.component';
import { ExpiryComponent } from './modules/error/expiry/expiry.component';
import { LoginComponent } from './modules/auth/login/login.component';
import { KycComponent } from './modules/auth/kyc/kyc.component';
import { EmailUpdateComponent } from './modules/auth/email-update/email-update.component';
import { ForgotPasswordComponent } from './modules/auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './modules/auth/reset-password/reset-password.component';
import { PaymentSuccessV2Component } from './modules/payment-success-v2/payment-success-v2.component';
import { OtpComponent } from './modules/auth/otp/otp.component';
import { PageNotFoundComponent } from './modules/page-not-found/page-not-found/page-not-found.component';
import { CreateQuoteV2Component } from './modules/create-quote/create-quote-v2/create-quote-v2.component';
import { QuoteDetailsV2Component } from './modules/create-quote/quote-details-v2/quote-details-v2.component';
import { QuoteEditV2Component } from './modules/create-quote/quote-edit-v2/quote-edit-v2.component';
import { QuoteSummaryV2Component } from './modules/create-quote/quote-summary-v2/quote-summary-v2.component';
import { DocumentUploadV2Component } from './modules/create-quote/document-upload-v2/document-upload-v2.component';
import { ViewPolicyV2Component } from './modules/create-quote/view-policy-v2/view-policy-v2.component';
import { DocumentSubmittedV2Component } from './modules/create-quote/document-submitted-v2/document-submitted-v2.component';
import { ExistingLivaErrorComponent } from './modules/error/existing-liva-error/existing-liva-error.component';
import { PaymentWaitingComponent } from './modules/create-quote/payment-waiting/payment-waiting.component';
import { AuthGaurd } from './Auth-gaurd';


const routes: Routes = [
    // 🥇 **Specific Static Routes**
    { path: 'verify-order', component: PaymentSuccessV2Component },
    { path: 'journey-done', component: PaymentSuccessV2Component },
    { path: 'login', component: LoginComponent },
    { path: 'not-found', component: PageNotFoundComponent },
    { path: 'otp', component: OtpComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'kyc', component: KycComponent },
    { path: 'email-update', component: EmailUpdateComponent },
    { path: ':customerUuid/app-expiry', component: ExpiryComponent },


    // 🛑 **Wildcard Route for 404**
    { path: '404', component: PageNotFoundComponent },

    {
        path: '',
        loadChildren: () => import('./modules/landing/landing.module').then((m) => m.LandingModule)
    },

    // 🥈 **Dynamic Route with Condition**
    {
        path: '',
        canMatch: [() => {
            const customerUuid = sessionStorage.getItem('customerUuvid');
             const idCheck = customerUuid == 'undefined' || customerUuid == 'null' || customerUuid == null || customerUuid == undefined? false : true;
            return idCheck
        }],
        loadChildren: () => import('./modules/create-quote/create-quote.module').then((m) => m.CreateQuoteModule)
    },

    // 🥉 **Default Global Route**


    { path: '**', component: ErrorComponent },




];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
