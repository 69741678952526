export const TOKEN = "token";
export const TPA_ID = "tpaId";
export const EMIRATE_ID = "emirateId";
export const GROUP_ID = "groupId";
export const PLAN_ID = "planId";
export const ROLE = "role";
export const USERID = "userId";
export const CUSTOMERUUVID = "customerUuvid";
export const CUSTOMERUSERID = "customerUserId";
export const EMAIL = "email";
export const CUSTOMERNAME = "customerName";
export const SESSIONSTATUSFORRETREIVEQUOTE = 'sessionStatusForRetreiveQuote';
export const previousWorkflow = 'previousWorkflow';
export const QUOTEENQUIRYID = 'quoteEnquiryId';
export const PREVIOUSWORKFLOW = 'previousWorkflow';
export const VERSION = 'version';
export const CURRENTWORKFLOW = 'currentWorkFlow';
