
<div *ngIf="type === 'select'" class="font-aller"  >
  <div (click)="$event.stopImmediatePropagation()">
  <div *ngIf="type === 'select'"
    (click)="changeDropdownStatus(id)"
    class="w-full  py-[.45rem] px-5 flex justify-between border border-[#999999] rounded-2xl items-center sm:border-0 md:border-0 md:py-0 h-[4.5rem]"  >
    <div class="flex items-center" (click)="$event.stopImmediatePropagation()" >
      <label for="" class="text-base md:text-[1.375rem] md:text-[#666666]">{{ title }} </label>
    </div>
    <div
      class="flex items-end justify-end  rounded-2xl sm:border-0 w-[57%] xs:w-[54%] md:w-[65%] md:border-[#999999]   md:py-0 md:border-none"
      >
      <div class="relative group w-full"  (click)="$event.stopImmediatePropagation()">
        <button id="dropdown-button" (click)="changeDropdownStatus(id)"
          class="inline-flex justify-end  items-center w-full text-xs font-semibold xs:font-medium py-[.45rem] md:border md:rounded-2xl focus:outline-[#FF6B00]
            md:border-[#999999] md:px-5 md:h-14">
          <span class="text-base font-bold md:text-[1.375rem] flex justify-end capitalize">{{ selected | titlecase }}</span>
          <img src="assets/icons/dropdown-arrow.png" class="w-5 h-5" alt="">
          <!-- <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 -mr-1" viewBox="0 0 20 20" fill="currentColor"
            aria-hidden="true">
            <path fill-rule="evenodd"
              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd" />
          </svg> -->
        </button>
        <div id="dropdown-menu" [ngClass]="showDropdown ? '' : 'hidden'"
          class="absolute right-0 mt-2 shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 z-40 max-h-60 min-w-40 overflow-auto" >
          <input id="search-input"
            class="text-base block w-full px-[1.7dvh] py-[0.9dvh] border-[#f6f6f6f6] focus:outline-[#FF6B00] " type="text"
            placeholder="Search..." autocomplete="off" [(ngModel)]="searchInputValue" (keyup)="showSearchItem()" />
          <button
            class="block w-full text-center px-4 py-2 hover:bg-gray-100 active:bg-blue-100 cursor-pointer text-base focus:outline-[#FF6B00] capitalize"
            *ngFor="let option of searchedItmes" (click)="dropDownItemSelected(title, option.description, option.code)">
            {{ option.description | titlecase }}
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>

</div>

<div *ngIf="type === 'gender'"
  class="w-full p-[0.9dvh] flex justify-between bg-[#F6F6F6F6] rounded-3xl items-center font-aller">
  <label for="" class="text-xs md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>
  <div class="flex items-center justify-center">
    <div class="relative group">
      <button id="dropdown-button" (click)="changeDropdownStatus(id)"
        class="inline-flex justify-center items-center w-full text-xs font-semibold    border-none focus:outline-none border px-[.9dvh]">
        <span class="mr-2 md:text-[1.375rem]">{{ selected }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 ml-2 -mr-1" viewBox="0 0 20 20" fill="currentColor"
          aria-hidden="true">
          <path fill-rule="evenodd"
            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clip-rule="evenodd" />
        </svg>
      </button>
      <div id="dropdown-menu" [ngClass]="showDropdown ? '' : 'hidden'"
        class="absolute right-0 mt-2    shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 z-40 max-h-60 min-w-40 overflow-auto">
        <!-- <input id="search-input" class="block w-full px-[1.7dvh] py-[0.9dvh]  border     border-[#f6f6f6f6] focus:outline-none" type="text" placeholder="Search..." autocomplete="off" [(ngModel)]="searchInputValue"  (keyup)="showSearchItem()"> -->
        <button
          class="block w-full text-center px-4 py-2 hover:bg-gray-100 active:bg-blue-100 cursor-pointer    text-xs"
          (click)="dropDownItemSelected(title, 'M')">
          M
        </button>
        <button
          class="block w-full text-center px-4 py-2 hover:bg-gray-100 active:bg-blue-100 cursor-pointer    text-xs"
          (click)="dropDownItemSelected(title, 'F')">
          F
        </button>
        <button
          class="block w-full text-center px-4 py-2 hover:bg-gray-100 active:bg-blue-100 cursor-pointer    text-xs"
          (click)="dropDownItemSelected(title, 'O')">
          O
        </button>
      </div>
    </div>
  </div>
</div>


<div *ngIf="type === 'static' && (dbField !== 'firstName' && dbField !== 'lastName' && dbField !== 'fullName')" class="font-aller">
  <div
    class="w-full  py-6 px-5 flex justify-between items-center bg-[#E5E5E5] rounded-2xl md:bg-transparent md:py-0 md:px-5">
    <label for="" class="text-base md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>
    <p
      class="text-base font-bold text-right md:text-[1.375rem]  md:border-[#999999] md: bg-[#E5E5E5]  md:py-4 md:px-5 md:w-[65%] md:rounded-2xl md:h-14">
      {{ selected  }}
    </p>
    <!-- <img src="assets/icons/edit.svg" *ngIf="showMakeModel && !enableChassisEdit" class="edit py-[2] pr-1"
      (click)="editChassisNumber()" /> -->
  </div>
</div>

<div *ngIf="type === 'static' && (dbField == 'firstName' || dbField == 'lastName' || dbField == 'fullName')" class="font-aller tooltip-container">
  <div
  (click)="toggleTooltip()" class="relative w-full py-6 px-5 flex justify-between items-center bg-[#E5E5E5] rounded-2xl md:bg-transparent md:py-0 md:px-5">
    <label for="" class="text-base md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>
    <p
      class="text-base font-bold text-right md:text-[1.375rem] md:border-[#999999] md:bg-[#E5E5E5] md:py-4 md:px-5 md:w-[65%] md:rounded-2xl md:h-14 cursor-pointer"
      [title]="fullNameObject.fullName.length > 0 
        ? fullNameObject.fullName 
        : (fullNameObject.firstName + ' ' + fullNameObject.lastName)">
      {{ selected }}
    </p>
    <div *ngIf="showTooltip && (fullNameObject.fullName?.trim() || (fullNameObject.firstName?.trim() || fullNameObject.lastName?.trim()))"
     class="md:hidden absolute left-1/2 transform -translate-x-1/2 bg-white border border-black px-2 py-2 shadow-lg z-10 text-center bottom-full mb-2 text-sm sm:text-base md:text-lg lg:text-xl">
      {{ fullNameObject.fullName?.trim() || (fullNameObject.firstName?.trim() + ' ' + fullNameObject.lastName?.trim()) }}
     <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2 border-8 border-transparent"></div>
  </div>
  </div>
</div>


<div *ngIf="type === 'email'" class="w-full font-aller">
  <div
    class="w-full  md:py-0 py-4 px-5 flex justify-between border border-[#999999] rounded-2xl items-center sm:border-0 md:border-0">
    <label for="" class="text-base w-[43%] xs:w-[46%] md:w-[35%] md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>

     <!-- <input [(ngModel)]="emailValue" type="email"
      class="text-right w-1/2 md:text-[1.375rem] font-bold  text-base p-2 outline-[#FF6B00] md:w-[60%] md:border md:border-[#999999] md:py-4 md:px-5 md:rounded-2xl" [value]="selected"
      (blur)="sendValue($event, title, emailValue)" /> -->

      <input [(ngModel)]="emailValue" type="email"
      class=" md:hidden text-right w-[57%] xs:w-[54%]  font-bold  text-base py-[.45rem] pl-2 outline-[#FF6B00]" [value]="selected"
      (blur)="sendValue($event, title, emailValue)" (blur)="checkLengthOfNumber($event, type)" />

      <input [(ngModel)]="emailValue" type="email"
      class="hidden md:flex  text-right md:text-[1.375rem] font-bold   outline-[#FF6B00] md:w-[65%] md:border md:border-[#999999]  md:py-[.67rem] md:px-5 md:rounded-2xl" [value]="selected"
      (blur)="sendValue($event, title, emailValue)" (blur)="checkLengthOfNumber($event, type)" />
  </div>
  <p *ngIf="errorObject.emailEmptyError" class="text-xs text-red-500 text-center md:ml-16">
    Email address is required
  </p>
  <p *ngIf="errorObject.emailWrongFormatError" class="text-xs text-red-500 text-center">
    Please enter a valid email address
  </p>
  <p *ngIf="mobileNumberValidation.emailLengthCheck" class="text-xs text-red-500 text-center">
    Email address length is limited to 60 characters
  </p>
</div>


<div class="w-full font-aller" *ngIf="type === 'text'">

  <div
    class="w-full py-4 px-5 flex justify-between border border-[#999999] rounded-2xl items-center sm:border-0 md:border-0 md:py-0">
    <label for="" class="text-base md:w-[35%] w-[43%] xs:w-[46%]   md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>

    <!-- Conditionally format input value only for Emirates ID -->
    <input [(ngModel)]="inputValue" type="text"
    class="text-right  w-[57%] xs:w-[54%]  text-base font-bold py-[.45rem] pl-2 outline-[#FF6B00] md:text-[1.375rem] md:w-[65%] md:border md:border-[#999999] md:py-[.95rem] md:px-5 md:rounded-2xl"
    [value]="title === 'Emirates ID' ? formatEmiratesId(inputValue) : inputValue" 
    (change)="sendValue($event, title, title === 'Emirates ID' ? unformatEmiratesId(inputValue) : inputValue)"
    [attr.maxlength]="title === 'Emirates ID' ? 15 : null" 
     />
  </div>

  <p *ngIf="errorObject.firstNameLengthError" class="text-xs text-red-500 text-center">
    First name is required
  </p>
  <p *ngIf="errorObject.lastNameLengthError" class="text-xs text-red-500 text-center">
    Last name is required
  </p>
  <p *ngIf="errorObject.plateNumberPatternError" class="text-xs text-red-500 text-center">
    Please enter a valid plate number
  </p>
</div>




<div *ngIf="type === 'edit-text'" class="font-aller w-full">

  
  <div class="w-full">
    <div *ngIf="showMakeModel"
      class="w-full  py-4 px-5 flex justify-between items-center border border-[#999999] rounded-2xl sm:border-0 md:border-0 md:py-0">
      <label for="" class="text-base w-1/2 md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>

      <div
        class="md:border md:border-[#999999] md:py-4 md:px-5 md:rounded-2xl md:w-[60%] flex justify-end items-center">
        <p class="text-base font-bold md:text-[1.375rem]">
          {{ selected }}
        </p>
        <!-- <img src="assets/icons/edit.svg" *ngIf="showMakeModel && !enableChassisEdit" class="edit ml-2 cursor-pointer"
          (click)="editChassisNumber()" /> -->
        
          <ng-container *ngIf="!showMakeModel">
            <!-- md:border-0 md:py-0 -->
            <div
              class="w-full flex justify-between items-center  py-4 px-5 border border-[#999999] rounded-2xl sm:border-0 ">
              <label for="" class="text-base w-1/2 md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>
              <input [(ngModel)]="inputValue" type="text"
                class="text-right w-1/2 md:text-[1.375rem]  font-bold text-base p-2 outline-[#FF6B00] bg-white md:border md:border-[#999999] md:py-4 md:px-5 md:rounded-2xl"
                [value]="selected" (change)="sendValue($event, title, inputValue)" />
              <img src="assets/svg/tick-red.png" *ngIf="!showMakeModel && enableChassisEdit"
                class="h-6 w-6 object-fill mx-2" (click)="enableTick()" />
              <img src="assets/svg/close.png" *ngIf="!showMakeModel && enableChassisEdit" class="h-6 w-6 object-fill"
                (click)="enableCancel()" />
            </div>
          </ng-container>
        
      </div>
    </div>
  </div>
  <div>
    <ng-container *ngIf="!showMakeModel">
      <div class="w-full flex justify-between items-center py-4 px-5 border border-[#999999] rounded-2xl sm:border-0 md:border-0 md:py-0">
        <label for="" class="text-base w-1/2 md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>
        <div class="flex items-center w-1/2 justify-end md:border md:border-[#999999] md:rounded-2xl md:py-4 md:px-5 md:w-[60%]">
            <input [(ngModel)]="inputValue" type="text"
            class="text-right w-full md:text-[1.375rem] font-bold text-base p-2 outline-[#FF6B00] bg-white"
            [value]="selected" (change)="sendValue($event, title, inputValue)" />
          <img src="assets/svg/tick-red.png" *ngIf="!showMakeModel && enableChassisEdit" class="h-6 w-6 object-fill mx-2 rounded-2xl"
            (click)="enableTick()" />
          <img src="assets/svg/close.png" *ngIf="!showMakeModel && enableChassisEdit" class="h-6 w-6 object-fill rounded-2xl"
            (click)="enableCancel()" />
        </div>
      </div>
      
      
    </ng-container>
  </div>

  <p *ngIf="errorObject.chasisNumberLengthError" class="text-xs text-red-500 text-center">
    Chassis number must be of 17 characters
  </p>
  <p *ngIf="errorObject.chasisNumberInvalidError" class="text-xs text-red-500 text-center">
    Enter a valid chassis number
  </p>
  <div class="text-xs mt-1 ms-2 md:text-[1.0rem] md:px-4  flex items-end justify-end">
    {{ vehicleSpecification.make }}, {{ vehicleSpecification.model }},
    {{ vehicleSpecification.variant }}, {{ vehicleSpecification.year }}
  </div>
</div>


<div class="w-full font-aller" *ngIf="type === 'number'">
  <div
    class="w-full  py-4 px-5 flex justify-between border border-[#999999] rounded-2xl items-center sm:border-0 md:border-0 md:py-0">
    <label for="" class="text-base md:w-[35%] w-[43%] xs:w-[46%]  md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>
    <input [(ngModel)]="inputValue" type="text"
      class="text-right w-[57%] xs:w-[54%] md:text-[1.375rem]    font-bold text-base py-[.45rem] pl-2 outline-[#FF6B00] md:w-[65%] md:border md:border-[#999999] md:py-[.95rem] md:px-5 md:rounded-2xl"
      [value]="selected" (blur)="checkLengthOfNumber($event, type)" (keydown)="checkAllowedInput($event)" (change)="sendValue($event, title, inputValue)" />
  </div>
  <p *ngIf="mobileNumberValidation.mobileNumberEmptyError" class="text-xs text-red-500 text-center">
    Mobile number is required
  </p>
  <p *ngIf="errorObject.mobileNumberWrongFormatError" class="text-xs text-red-500 text-center">
    Please enter a valid mobile number
  </p>
  <p *ngIf="mobileNumberValidation.mobileNumberlength" class="text-xs text-red-500 text-center">
    Mobile number can't be more than 16 digits
  </p>
  <p *ngIf="errorObject.yearOfManufactureError" class="text-xs text-red-500 text-center">
    Enter a valid year of manufacture
  </p>
</div>


<div *ngIf="type === 'date'" class="w-full font-aller">
  <div
    class="w-full py-4 px-5 flex justify-between border border-[#999999] rounded-2xl items-center sm:border-0 md:border-0 md:py-0">
    <label for="" class="text-base md:w-[35%] w-1/2  md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>
      <input type="date"
        class="text-right  md:text-[1.375rem] w-1/2 md:w-[65%] font-bold text-base py-[.39rem] pl-2 bg-transparent outline-[#FF6B00] md:border md:border-[#999999] md:py-[.888rem] md:px-5 md:rounded-2xl"
        [(ngModel)]="inputDateValue" [min]="dateLimitValue" [max]="maxDate"  (change)="sendValue($event, title, inputDateValue)" />
  </div>
  <p *ngIf="errorObject.policyStartDateMinError" class="text-xs text-red-500 text-center">
    Policy start date cannot be a past date
  </p>
  <p *ngIf="errorObject.policyStartDateMaxError" class="text-xs text-red-500 text-center">
    Policy start date must be on or before previous policy expiry date
  </p>
</div>




<div class="w-full font-aller" *ngIf="type === 'dob'">
  <div
    class="w-full  py-4 px-5 flex justify-between items-center border border-[#999999] rounded-2xl sm:border-0 md:border-0 md:py-0 md:px-5">
    <label for="" class="text-base w-1/2 md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>
    <input type="date"
      class="text-right w-1/2 md:text-[1.375rem]     font-bold text-base p-2 outline-[#FF6B00] md:w-[60%] md:border md:border-[#999999] md:py-4 md:px-5 md:rounded-2xl"
      [(ngModel)]="inputDateValue" [max]="dateLimitValue" (change)="sendValue($event, title, inputDateValue)" />
  </div>
  <p *ngIf="errorObject.dateOfBirthError" class="text-xs text-red-500 text-center">
    Age should not be less than 18 years
  </p>
</div>

<div class="w-full font-aller" *ngIf="type === 'policyExpiryDate'">
  <div
    class="w-full  py-4 px-5 flex justify-between items-center bg-[#E5E5E5] rounded-2xl md:bg-transparent  sm:border-0 md:border-0 md:py-0">
    <label for="" class="text-base md:w-[35%] w-1/2 md:text-[1.375rem] md:text-[#666666]">{{ title }}</label>
    <input type="date"
      class="text-right w-1/2 md:w-[65%] md:text-[1.375rem] font-bold text-base py-[.44rem] pl-2 outline-[#FF6B00] bg-transparent   md:border-none md:border-[#999999]  md:bg-[#E5E5E5] md:py-[.938rem] md:px-5 md:rounded-2xl"
      [(ngModel)]="policyExpiryDate" [disabled]="dbField == 'policyExpiryDate'"
      (change)="sendValue($event, title, policyExpiryDate)" />
  </div>
</div>

<!-- <p *ngIf="errorObject.policyStartDateError" class="text-xs text-red-500 text-center">Policy Start date shouldn't be less than the previous plan expiry date</p> -->


<!-- Mobile View -->
<div *ngIf="type === 'radio'"
  class=" rounded-2xl">
  <p class="text-[.8125rem] px-[.75rem] pt-[1.25rem] pb-[1.0625rem] bg-[#FFE1CC] rounded-t-2xl text-center">The information you provide here may impact your premium. </p>
  <div class="font-aller w-full flex justify-between bg-[#FFF0E5] items-center  py-[1.125rem] px-5 mb-[1px]"
  [ngClass]="hideSumInsuredSlider ? 'rounded-b-2xl' : ''"
  >
    <div class="flex flex-col  ">
      <label for="" class="text-base font-bold md:text-[1.375rem] leading-[1.375rem] md:hidden ">
        {{ title.slice(0, 24) }}
      </label>
      <label for="" class="text-base font-bold  md:text-[1.375rem] leading-[1.375rem md:hidden">
        {{ title.slice(25, 50) }}
      </label>
      <label for="" class="text-base font-bold  md:text-[1.375rem] leading-[1.375rem hidden md:flex">
        {{ title }}
      </label>
    </div>
  
    <div class="flex items-center gap-2 font-normal text-xs xs:font-normal ">
      <span class="text-base px-5 py-1 rounded-2xl outline-none md:text-[1.375rem]"
        [ngClass]="ncbYes ? ' bg-[#FFE1CC] text-black' : 'bg-[#E5E5E5] text-[#FFFFFF]'"
        (click)="sendValue('', title, 0)">Yes</span>
      <span class="text-base px-5 py-1 rounded-2xl outline-none md:text-[1.375rem]"
        [ngClass]="ncbNo ? ' bg-[#FFE1CC] text-black' : 'bg-[#E5E5E5] text-[#FFFFFF]'"
        (click)="sendValue('', title, 99)">No</span>
    </div>
  </div>
  <!-- <hr class="h-[1px] w-full border border-white"> -->
  <div class="bg-[#FFF0E5] rounded-b-2xl px-5 py-[1.125rem] " [ngClass]="hideSumInsuredSlider ? 'hidden' : ''">
    <div class="flex justify-between items-center mb-[.72rem]">
      <div class="flex items-center gap-1">
        <h3 class="font-bold text-xl md:text-[1.375rem] leading-[1.19rem]">Sum Insured</h3>
        <!-- <img src="/assets/svg/info.svg" alt="" /> -->

      </div>
      <h3 class="font-bold text-xl md:text-[1.375rem] leading-[1.19rem]"><span class="mr-7">AED</span> {{ rangeValue | number }}</h3>
    </div>
    <div class="mb-[0.7rem]"> 
      <ngx-slider [(value)]="rangeValue" [options]="sliderOptions" (valueChange)="sendRangeSliderValue($event)"></ngx-slider>
    </div>
    <div class="flex items-center justify-between text-xs -mt-1">
      <p class="text-black text-xs  md:text-[1rem]">AED {{ minSumInsured | number }}</p>
      <p class="text-black text-xs  md:text-[1rem]">AED {{ maxSumInsured | number }}</p>
    </div>
  </div>
  <!-- <div *ngIf="ncbYes" class=" md:hidden text-[0.8125rem] font-light mt-1 w-full text-left">Selecting "Yes" will change your premium</div> -->

</div>

<!-- Web View -->
 <!-- <div *ngIf="type === 'radio'" class="flex flex-col items-center justify-center md:py-[0.70rem] md:px-5"> 
   <div 
     class="font-aller w-full  justify-between rounded-2xl border border-[#999999] md:border-none hidden md:flex">
     <div>
       <label for="" class="text-base md:text-[1.375rem]  md:text-[#666666] ">
         {{ title }}
       </label>
     </div>
   
     <div class="flex items-center gap-2 font-semibold text-xs md:font-normal md:px-5">
       <span class="text-base px-5 py-1 rounded-2xl md:text-[1.375rem] outline-none "
         [ngClass]="ncbYes ? 'bg-[#FFE1CC] text-black' : 'bg-[#E5E5E5] text-[#FFFFFF]'"
         (click)="sendValue('', title, 0)">Yes</span>
       <span class="text-base px-5 py-1 rounded-2xl md:text-[1.375rem] outline-none"
         [ngClass]="ncbNo ? 'bg-[#FFE1CC] text-black' : 'bg-[#E5E5E5] text-[#FFFFFF]'"
         (click)="sendValue('', title, 99)">No</span>
     </div>
    </div>
    <div *ngIf="ncbYes" class="hidden md:block text-[13px] font-light mt-1 w-full text-left">Selecting "Yes" will change your premium</div>
 </div> -->