<div class=" flex flex-col justify-between h-screen">
    <div>
        <app-header-v2 [backButton]="backButtonEnable" [pageName] = '"error-page"' class="px-8"></app-header-v2>
    </div>
    <div class="flex flex-col items-center justify-center p-4 ">
        <!-- <img src="/assets/svg/sad-face-icon.svg" alt="Sad Emoticon" class="h-28 mb-4"> -->
        <!-- <h1 class="text-4xl font-bold ">404 </h1> -->
        <!-- <h3 class="text-md font-medium">Page Not Found !</h3> -->
         <div class="flex flex-col justify-center items-center gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
                <g id="Group_609" data-name="Group 609" transform="translate(-186 -350.715)">
                  <circle id="Ellipse_125" data-name="Ellipse 125" cx="25.5" cy="25.5" r="25.5" transform="translate(186 350.715)" fill="#ff6900"/>
                  <g id="Group_608" data-name="Group 608" transform="translate(211.357 364.99)">
                    <line id="Line_52" data-name="Line 52" transform="translate(-0.357 23.725)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                    <line id="Line_51" data-name="Line 51" y2="18" transform="translate(-0.357 -0.275)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                  </g>
                </g>
              </svg>
              <p class="text-[1.688rem] font-bold">Ooops...</p>
              <div class="md:text-[1.625rem] font-light text-center">Sorry for the inconvenience, please call us at <span class="text-[#FF6900] font-bold"><br>
                <a [href]="'tel:' + mobileNumber">{{mobileNumber}}</a>
              </span>  or <span class="text-[#FF6900] font-bold"><a [href]="'mailto:' + contactEmail">{{contactEmail}}</a></span><br>
                and we’ll get everything sorted for you.  </div>

         </div>
        <!-- <p class="text-center md:text-[1.625rem]">We regret to inform you that we are unable to process your request at this time. Please try again later.</p> -->
         <button
            *ngIf="showRetryButton"
            class="mt-4 w-full bg-[#FF6900] cursor-pointer text-white text-xl font-bold h-12 rounded-full md:max-w-[18rem] md:block md:mx-auto disabled:opacity-50 disabled:cursor-not-allowed"
            (click)="retryCreateQuote()"
            [disabled]="isButtonDisabled"
            >
            Retry
        </button>
        <p class="mt-2" *ngIf="showRetryButton && retryRemainingTime !== 0" >You can retry in {{retryRemainingTime}} seconds</p>
    </div>
    <div>
        <app-footer-v2 class="w-full"></app-footer-v2>
    </div>
</div>
