<div class=" flex flex-col justify-between h-screen">
  <div>
      <app-header-v2 [backButton]="true" [pageName] = '"error-page"' class="px-8"></app-header-v2>
  </div>
  <div class="flex flex-col items-center justify-center p-4 ">
       <div class="flex flex-col justify-center items-center gap-3">

            <div class="md:text-[1.625rem] font-light text-center">Apologies for the inconvenience.Your quote, <span class="text-[#FF6900] font-bold">{{quotationNumber}},</span> has been forwarded to our team, <br>and one of our advisors will be in touch with you shortly<br>
            </div>

       </div>

  </div>
  <div>
      <!-- <app-footer-v2 class="w-full"></app-footer-v2> -->
  </div>
</div>
