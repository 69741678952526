<div
class=""
[ngClass]="{
 'mb-[12px]' : !showSupport,
 'mb-[9px]' :showSupport
}"
>
 <h3
 (click)="changeSupportStatus()"
 [ngClass]="{
    'text-[#666666] underline' : !showSupport,
    'text-black' :showSupport
 }"
 class="text-center mt-[14px]  text-[13px]"
 >Need Support?</h3>
 <div *ngIf="showSupport" class="flex flex-col gap-[3px] justify-center items-center mt-[10px]">
   <p class="text-[13px] font-bold  ">Call <span class="text-[#FF6900]">
    <a [href]="'tel:' + phoneNumber">{{phoneNumber}}</a>
   </span></p>
   <p class="text-[13px] font-bold text-[#FF6900] "><span class="text-black">Email </span> 
    <a [href]="'mailto:' + supportEmail">{{supportEmail}}</a>
   </p>
 </div>
</div>
