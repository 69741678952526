import { Injectable} from '@angular/core';
import { CreateQuoteService } from './create-quote/create-quote.service';
import { vehicleDetailsDTO } from '../interface/quote-details-interface';
import { SessionService } from './session-service/session.service';
import { Router } from '@angular/router';
import { QuoteSummaryService } from './quote-summary/quote-summary.service';
import { ClipboardService } from 'ngx-clipboard';
import { ToastrService } from 'ngx-toastr';
import { CURRENTWORKFLOW, CUSTOMERUUVID, PREVIOUSWORKFLOW, QUOTEENQUIRYID, TOKEN, VERSION } from '../session-variables';
import { flowBasedRouting } from '../mock-data';
import { NgxSpinnerService } from 'ngx-spinner';

interface VehicleDetails{
  vehicleName: string;
  vehicleValue: number;
  policyStartDate: string;

}
@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  vehicleDetails = {
    vehicleName: 'Toyota Land Cruiser 2022',
    vehicleValue: 0,
    policyStartDate: '30/03/2024',
    firstName: 'salil'
  };
  flowdata = flowBasedRouting;
  quoteEnquiryId: number;
  previousWorkFlow: string;
  currentWorkflow: string;
  version: number;
  token: string;
  customerUuvid: string;
  profileDropdownStatus: boolean = false

  constructor(
    private createQuoteService:CreateQuoteService,
    private sessionStorage: SessionService,
    private route: Router,
    private quoteSumaryService: QuoteSummaryService,
    private router: Router,
    private clipboardService: ClipboardService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  async getCustomerVehicleDetailsForQuotes():Promise<void | VehicleDetails>  {
    try {
      let result = await this.createQuoteService.createCustomerVehicleDetailsForQuote();
      if (result.response.length > 0) {
        this.vehicleDetails.policyStartDate = this.formatDate(result.response[0].policyStartDate);
        this.vehicleDetails.vehicleName =  result.response[0].make + " " + result.response[0].model + " " + result.response[0].yearOfManufacture ;
        this.vehicleDetails.vehicleValue = Number(result.response[0].vehicleValue);
        this.vehicleDetails.firstName = result.response[0].firstName;
        return this.vehicleDetails
      }
    } catch (exception) {
      // this.toaster.error(exception.message);
    } finally {

    }
  }

  formatDate(policyStartDate:string){
    const formattedDate = policyStartDate.slice(0,10).split("-").reverse().join("/")
    return formattedDate
  }

  async redirecToPreviousPage() {
    try {
      let isQuoteFailedBackbutton:boolean=false;
      this.spinner.show()
      this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID)
      this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
      this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
      this.customerUuvid = this.sessionStorage.get(CUSTOMERUUVID);
      this.version = this.sessionStorage.get(VERSION);
      let flows = this.flowdata.filter(flowObj => { return this.currentWorkflow.trim().toLowerCase() == flowObj.flowName.trim().toLowerCase() });
      const token = this.sessionStorage?.get(TOKEN);
      /**
       * Actions of Quote Failed Error Page Back Button
       * 1.Activate last success quote by setting active_status of failed to 0 and active_status of last success as 1
       * 2.Get Last success quote enquiry Id
       * 3.Get Last success Quote last Work flow status like previous and current work flow and update same in Workflow transaction table.
       * 4.Redirect to previous flow.
       */
      if(this.currentWorkflow==="quote_failed"){
        isQuoteFailedBackbutton=true;
        let lastSuccessId=await this.createQuoteService.errorRedirectQuote(this.customerUuvid)
        this.sessionStorage.set(QUOTEENQUIRYID,lastSuccessId.response.quoteEnquiryId);
        this.sessionStorage.set(PREVIOUSWORKFLOW,lastSuccessId.response.lastSuccessWorkFlow.previousWorkFlow);
        this.sessionStorage.set(CURRENTWORKFLOW,lastSuccessId.response.lastSuccessWorkFlow.currentWorkFlow);
        this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID)
        this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
        this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
        await this.updateWorkFlowForFailedBackButton();
        await this.getCustomerLastWorkflow();
        }
        else if(this.currentWorkflow==="payment_failed" || this.currentWorkflow==="lexis_nexis_failed"){
          this.sessionStorage.set(PREVIOUSWORKFLOW,"selected_product");
          this.sessionStorage.set(CURRENTWORKFLOW,"quote_confirmed");
          this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID)
          this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
          this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
          await this.updateWorkFlow();
          await this.getCustomerLastWorkflow();
          this.route.navigateByUrl(`${this.customerUuvid}/${flows[0].backbuttonRoute}`)
          }
      else{
      await this.updateWorkFlow();
      await this.getCustomerLastWorkflow();
      this.route.navigateByUrl(`${this.customerUuvid}/${flows[0].backbuttonRoute}`)
    }
    } catch (exception) {
    } finally {
      this.spinner.hide()
    }
  }

  async updateWorkFlow() {
    try {
      let workFlowDetail = {
        quoteEnquiryId: this.quoteEnquiryId,
        previousQuoteStatus: this.currentWorkflow, //For back button the flow is interchanged so previousflow=currentFlow ,currentFlow=PreviousFlow
        currentQuoteStatus: this.previousWorkFlow,
        version: this.version
      }
      const response = await this.createQuoteService.updateWorkFlowStatus(workFlowDetail)
    } catch (error) {
    }
  }

  async updateWorkFlowForFailedBackButton() {
    try {
      let workFlowDetail = {
        quoteEnquiryId: this.quoteEnquiryId,
        previousQuoteStatus: this.previousWorkFlow, //For back button the flow is interchanged so previousflow=currentFlow ,currentFlow=PreviousFlow
        currentQuoteStatus: this.currentWorkflow,
        version: this.version
      }
      const response = await this.createQuoteService.updateWorkFlowStatus(workFlowDetail)
    } catch (error) {
    }
  }

  async getCustomerLastWorkflow() {
    try {
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(this.flowdata);

      this.router.navigateByUrl(`${this.customerUuvid}/${getFLows[0].route}`)
    } catch (exception) {

    }
  }

}
